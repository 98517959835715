<template>
  <v-container
    fluid
    pa-0
  >
    <v-overlay :value="loading" z-index=8>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- <v-row class="ma-0" v-if="!loading">
      <v-col cols=12 class="success-page-wrapper text-center pa-0">
        <img src="@/assets/svg/logo-short.svg" class="logo" />
        <div class="success-text">Success!</div>
        <v-divider class="divider"/>
        <div class="description px-4 mt-8 mb-2">
          {{ validToken ? 'Thanks for confirming your email.' :
            'Your email confirmation link appears to be invalid.' }}
        </div>
        <router-link
          :to="{ name: 'Login'}"
          class="return-to-login"
          >
          Return to Login Page
        </router-link>
      </v-col>
    </v-row>
    <v-row class="ma-0 w-100">
      <v-col class="pa-0">
        <v-img width="100%" :aspect-ratio="aspectRatio" src="@/assets/svg/theme/common.svg" />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Success',
  computed: {
    aspectRatio() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return undefined;
    },
  },
  data() {
    return {
      loading: true,
      validToken: null,
    };
  },
  methods: {
    ...mapActions('user', ['confirmEmail']),
  },
  async mounted() {
    const { confirmToken } = this.$route.params;
    await this.confirmEmail({ confirmToken });
    this.$router.push('/login');
    this.loading = false;
  },
};
</script>
